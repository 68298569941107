import React from 'react'
import { LoaderContainer, Circle, Loader } from './Loader.style'
import styled from 'styled-components'
import Skeleton from '@mui/material/Skeleton'

const AppLoader = () => (
  <Loader>
    <LoaderContainer>
      <Circle />
      <Circle />
      <Circle />
    </LoaderContainer>
  </Loader>
)

const LoadContainer = styled.div`
 width: 100%;
 height: auto;
`

const ListLoader = () => (
  <LoadContainer>
    <Skeleton />
    <Skeleton animation={false} />
    <Skeleton />
    <Skeleton animation='wave' />
    <Skeleton animation='wave' />
  </LoadContainer>
)

export { AppLoader, ListLoader }
