import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  LinkContainer,
  AuthButton,
  EmailValidationInput
} from './styledComponents'
import { Link } from '@mui/material'
import { ValidationTextField } from '@common'

const LOGIN_SCHEMA = yup.object().shape({
  username: yup
    .string()
    .email('Email address must be valid')
    .required('Email address is required'),
  password: yup.string().required('Password Required.')
})

export const NormalLoginForm = ({ login }) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(LOGIN_SCHEMA)
  })

  const handleLogin = async (fields) => {
    login(fields.username.toLowerCase(), fields.password)
  }

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <EmailValidationInput
        fieldConfig={{ name: 'username', label: 'Email Address' }}
        control={control}
        error={errors.username}
        data-cy='usernameInput'
        id='usernameInput'
      />
      <ValidationTextField
        fieldConfig={{ name: 'password', label: 'Password' }}
        control={control}
        type='password'
        error={errors.password}
        data-cy='passwordInput'
        id='passwordInput'
      />
      <AuthButton
        fullWidth
        type='submit'
        variant='contained'
        color='secondary'
        data-cy='loginButton'
      >
        Log In
      </AuthButton>
      <LinkContainer>
        <Link
          component={RouterLink}
          variant='body2'
          to='/reset-password'
          data-cy='forgotPasswordLink'
        >
          Forgot Password?
        </Link>
      </LinkContainer>
    </form>
  )
}
